<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="3" v-for="hr in controlledAccessMenu" :key="hr.name">
        <v-alert
          outlined
          :color="$store.state.primaryColor"
          @click="openHRFunction(hr.route)"
          style="cursor: pointer"
        >
          <v-layout class="justify-space-between">
            <span class="subtitle-2 light-blue--text text--darken-4">{{
              hr.name
            }}</span>
          </v-layout>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import jwtdecode from "jwt-decode";
export default {
  data: () => ({}),

  computed: {
    controlledAccessMenu() {
      return this.hrFunctions.filter((val) => {
        if (val.level.includes(this.userAccess)) {
          return val;
        }
      });
    },
    hrFunctions() {
      return [
        { name: this.$t("employees list"), route: "ViewEmployees", level: [1] },
        { name: this.$t("attendance"), route: "Attendance", level: [1, 3] },

        {
          name: this.$t("attendance"),
          route: "ViewAttendanceDetails",
          level: [2],
        },
        { name: this.$t("payroll"), route: "Payroll", level: [1, 3] },
        {
          name: this.$t("payroll"),
          route: "ViewPayrollDetails",
          level: [2],
        },
        { name: this.$t("overtimes"), route: "Overtimes", level: [1, 3] },
        {
          name: this.$t("overtimes"),
          route: "ViewOvertimeDetails",
          level: [2],
        },
        { name: this.$t("leaves"), route: "Leave", level: [1, 3] },
        {
          name: this.$t("leaves"),
          route: "ViewIndividualLeaveRequests",
          level: [2],
        },
        { name: this.$t("shifts"), route: "Shifts", level: [1, 3] },
        {
          name: this.$t("shifts"),
          route: "ViewPersonalShift",
          level: [2],
        },
        {
          name: this.$t("expense claims"),
          route: "ExpenseClaims",
          level: [1, 3],
        },
        {
          name: this.$t("expense claims"),
          route: "ViewIndividualExpenseClaims",
          level: [2],
        },
      ];
    },
  },

  created() {
    this.checkAccessLevel();
  },

  methods: {
    checkAccessLevel() {
      //Fetching current user details
      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
      this.userAccess = data.accessLevel.isSuperAdmin
        ? 0
        : data.accessLevel.isOwner
        ? 1
        : data.accessLevel.isEmployee
        ? 2
        : 3;

      this.username = data.accessLevel.username;
    },

    openHRFunction(route) {
      if (this.userAccess == 2) {
        this.$router.push({ name: route, params: { id: this.username } });
      } else {
        this.$router.push({ name: route });
      }
    },
  },
};
</script>
